ul.avg-rate {
  list-style-type: none;
  padding-left: 17px;
}
ul.avg-rate li {
  color: #767676;
}

.w250 {
  width: 250px;
}
a.th-load-more.-oneX-load-more {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}
