body {
  font-family: "MecherleLegal", sans-serif;
}
/* oneX override */
.-oneX-page-loader {
  display: inline;
}
.oneX-col-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* size classess */
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-20 {
  margin-left: 20px !important;
}
.w700 {
  width: 900px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-30 {
  padding-top: 30px;
}
.text-center {
  text-align: center;
}
.hidden {
  display: none;
}

section:focus,
header:focus {
  outline-style: none;
}
