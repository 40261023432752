/******
  To change the size of the loader, set the width, then follow the instructions in comments to the right of each line.
  Using a width variable in SASS or SCSS would be incredibly time-saving.
******/
.f-column {
  display: flex;
  flex-direction: column;
}
.f-center {
  display: flex;
  justify-content: center;
}

svg.loader {
  width: 80px;
  height: 80px; /* height = width */
  x: 0px;
  y: 0px;
  viewbox: 0 0 80 80; /* 0, 0, width, width */
}

svg.loader circle {
  fill: transparent;
  stroke: #e1261c;
  stroke-width: 8; /* width x .1 */
  stroke-dasharray: 251.33; /* width x pi */
  transform-origin: 40px 40px 0; /* width x .5, width x .5, 0 */
  animation: loader 2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(-90deg);
    stroke-dashoffset: 62.83; /* .25 x pi x width */
  }
  16.67% {
    transform: rotate(27deg);
    stroke-dashoffset: 50.27; /* .20 x pi x width */
  }
  33.33% {
    transform: rotate(153deg);
    stroke-dashoffset: 75.4; /* .30 x pi x width */
  }
  58.33% {
    transform: rotate(320deg);
    stroke-dashoffset: 138.23; /* .55 x pi x width */
  }
  66.67% {
    transform: rotate(385deg);
    stroke-dashoffset: 138.23; /* .55 x pi x width */
  }
  83.33% {
    transform: rotate(516deg);
    stroke-dashoffset: 113.1; /* .45 x pi x width */
  }
  100% {
    transform: rotate(630deg);
    stroke-dashoffset: 62.83; /* .25 x pi x width */
  }
}
/* .-oneX--scrim__on {
  background-color: rgba(255, 255, 255, 0.9);
} */
